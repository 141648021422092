import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Icon,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Button,
} from '@material-ui/core';
import { GlobalContext } from '../../global-context';
import Loading from '../utils/Loading';
import fragments from '../utils/graphql/fragments';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    padding: theme.spacing(2.5),
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
    },
    position: 'relative',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    height: '100%',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  statNumber: {
    fontSize: '2.8rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginTop: 'auto',
  },
  numberRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 'auto',
    width: '100%',
  },
  statLabel: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    //marginLeft: theme.spacing(2),
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  applicantIcon: {
    backgroundColor: 'rgba(76, 175, 80, 0.12)',
  },
  messageIcon: {
    backgroundColor: 'rgba(25, 118, 210, 0.12)',
  },
  maintenanceIcon: {
    backgroundColor: 'rgba(255, 152, 0, 0.12)',
  },
  urgentIcon: {
    backgroundColor: 'rgba(229, 57, 53, 0.12)',
  },
  icon: {
    fontSize: '2.2rem',
    color: theme.palette.text.primary,
  },
  urgentCard: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  warningCard: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  infoCard: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
  },
  successCard: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  actionList: {
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listItem: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    borderRadius: '0 8px 8px 0',
    transition: 'background-color 0.2s ease, transform 0.2s ease',
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transform: 'translateX(4px)',
    },
  },
  itemBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 4,
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  urgentBorder: {
    backgroundColor: theme.palette.error.main,
  },
  warningBorder: {
    backgroundColor: theme.palette.warning.main,
  },
  successBorder: {
    backgroundColor: theme.palette.success.main,
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: 0,
      width: 40,
      height: 3,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
  badge: {
    marginLeft: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(4, 0),
    backgroundColor: theme.palette.divider,
  },
  statusChip: {
    margin: theme.spacing(0, 0.5),
  },
  booths: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 500,
    transition: 'color 0.2s ease',
    '&:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  actionButton: {
    marginTop: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    fontWeight: 500,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    },
  },
  chevronIcon: {
    fontSize: '2rem',
    color: theme.palette.text.secondary,
    transition: 'transform 0.2s ease',
  },
  cardLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      '& $chevronIcon': {
        transform: 'translateX(4px)',
      },
    },
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    overflow: 'hidden',
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const [highestOrderIdSeen, setHighestOrderIdSeen] = React.useState(0);
  const [newOrdersCount, setNewOrdersCount] = React.useState(0);
  const [isBlinking, setIsBlinking] = React.useState(false);
  const [lastSeenTimestamp, setLastSeenTimestamp] = React.useState('');

  // Load the highest order ID from localStorage on component mount
  React.useEffect(() => {
    const storedHighestId = localStorage.getItem('highestOrderIdSeen');
    const storedTimestamp = localStorage.getItem('lastSeenTimestamp');

    if (storedHighestId) {
      setHighestOrderIdSeen(parseInt(storedHighestId, 10));
    }

    if (storedTimestamp) {
      setLastSeenTimestamp(storedTimestamp);
    }
  }, []);

  // Blinking effect when new orders are present
  React.useEffect(() => {
    let blinkInterval;
    if (newOrdersCount > 0) {
      blinkInterval = setInterval(() => {
        setIsBlinking(prev => !prev);
      }, 800); // Slightly faster blinking for better visual feedback
    } else {
      setIsBlinking(false);
    }

    return () => {
      if (blinkInterval) {
        clearInterval(blinkInterval);
      }
    };
  }, [newOrdersCount]);

  // Function to mark orders as seen
  const markOrdersAsSeen = () => {
    if (newOrdersCount > 0) {
      // Get current timestamp
      const now = new Date();
      const timestamp = now.toLocaleString();

      // Update localStorage with the current highest order ID seen and timestamp
      localStorage.setItem('highestOrderIdSeen', highestOrderIdSeen.toString());
      localStorage.setItem('lastSeenTimestamp', timestamp);

      // Update state
      setLastSeenTimestamp(timestamp);
      setNewOrdersCount(0);

      // Optional: You could add a snackbar or toast notification here to confirm the action
      console.log(`Marked ${newOrdersCount} orders as seen. Highest ID: ${highestOrderIdSeen}`);
    }
  };

  const prepareData = orders => {
    if (!orders || !orders.length) return null;

    // Find the highest order ID in the current data
    const highestOrderId = Math.max(...orders.map(order => order.id));

    // Get the stored highest ID from localStorage
    const storedHighestId = parseInt(localStorage.getItem('highestOrderIdSeen') || '0', 10);

    // Update the highest order ID seen if needed
    if (highestOrderId > highestOrderIdSeen) {
      setHighestOrderIdSeen(highestOrderId);

      // Count new orders that have an ID higher than the previously seen highest ID
      const newOrders = orders.filter(order => order.id > storedHighestId);

      // Always set the new orders count, even on first load
      // The user needs to explicitly click "Mark as Seen" to clear them
      setNewOrdersCount(newOrders.length);
    }

    // Current date for comparisons
    const today = moment().startOf('day');

    // Start and end of current week for shipping this week view
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');

    // Helper function to check if a package has been dropped off based on tracking data
    const hasBeenDroppedOff = order => {
      const tracking = order.outbound_shipment_activity;

      // If no tracking info exists, it hasn't been dropped off
      if (!tracking) return false;

      // Simply check the shipped flag from the tracking data
      return tracking.shipped === true;
    };

    // Helper function to get effective shipping status based on tracking info
    const getEffectiveShippingStatus = order => {
      if (order.status === 'Delivered') return 'Delivered';

      if (order.status === 'Outbound') {
        return hasBeenDroppedOff(order) ? 'Dropped Off' : 'Ready For Drop-Off';
      }

      return order.status;
    };

    // Count orders by status
    const statusCounts = {};
    orders.forEach(order => {
      statusCounts[order.status] = (statusCounts[order.status] || 0) + 1;
    });

    // Orders that need to be prepared for shipping this week (within the current calendar week)
    // This will only include orders in New, Ready, or Outbound-but-not-dropped-off status
    const toShipSoon = orders
      .filter(order => {
        // Include orders in New or Ready status
        const needsPrep = ['New', 'Ready'].includes(order.status);

        // Also include Outbound orders that haven't been dropped off yet
        const isOutboundNotDroppedOff = order.status === 'Outbound' && !hasBeenDroppedOff(order);

        // Skip if neither condition is met
        if (!needsPrep && !isOutboundNotDroppedOff) return false;

        // Ensure ship_date is valid
        if (!order.ship_date) return false;

        // Parse the ship date
        const shipDate = moment(order.ship_date);
        if (!shipDate.isValid()) return false;

        // Check if it falls within this week
        return shipDate.isSameOrAfter(startOfWeek, 'day') && shipDate.isSameOrBefore(endOfWeek, 'day');
      })
      .sort((a, b) => moment(a.ship_date).diff(moment(b.ship_date)));

    // ALL orders shipping this week regardless of status
    const allShipThisWeek = orders
      .filter(order => {
        // Ensure ship_date is valid
        if (!order.ship_date) return false;

        // Parse the ship date
        const shipDate = moment(order.ship_date);
        if (!shipDate.isValid()) return false;

        // Check if it falls within this week
        return shipDate.isSameOrAfter(startOfWeek, 'day') && shipDate.isSameOrBefore(endOfWeek, 'day');
      })
      .sort((a, b) => moment(a.ship_date).diff(moment(b.ship_date)));

    // Enrich allShipThisWeek with effective status
    allShipThisWeek.forEach(order => {
      order.effectiveStatus = getEffectiveShippingStatus(order);
    });

    // For debugging - log the orders shipping this week
    console.log('All orders shipping this week:', allShipThisWeek.length);
    console.log('Orders to prepare for shipping this week (New/Ready/NotDroppedOff):', toShipSoon.length);
    console.log('Week range:', startOfWeek.format('YYYY-MM-DD'), 'to', endOfWeek.format('YYYY-MM-DD'));
    allShipThisWeek.forEach(order => {
      console.log(
        `Order ${order.id} - Status: ${order.status} - Effective: ${order.effectiveStatus} - Ship date: ${moment(
          order.ship_date
        ).format('YYYY-MM-DD')} - Event: ${order.event_name}`
      );
    });

    // Orders being returned to us (Inbound status)
    const returning = orders.filter(order => order.status === 'Inbound');

    // Orders that need packing by customer (after event date)
    const needsPacking = orders.filter(order => order.status === 'Packing');

    // Orders that require immediate attention
    const urgentOrders = [];

    // 1. Shipping today
    const shippingToday = orders.filter(order => {
      // Include orders in New or Ready status
      const needsPrep = ['New', 'Ready'].includes(order.status);

      // Also include Outbound orders that haven't been dropped off yet
      const isOutboundNotDroppedOff = order.status === 'Outbound' && !hasBeenDroppedOff(order);

      // Skip if neither condition is met
      if (!needsPrep && !isOutboundNotDroppedOff) return false;

      if (!order.ship_date) return false;

      const shipDate = moment(order.ship_date);
      return shipDate.isValid() && shipDate.isSame(today, 'day');
    });
    console.log(`Found ${shippingToday.length} orders shipping today`);
    urgentOrders.push(...shippingToday);

    // 1.5 Past ship date but not yet dropped off
    const pastShipDateNotDroppedOff = orders.filter(order => {
      // Only include Outbound orders that haven't been dropped off yet or New/Ready orders
      const needsPrep = ['New', 'Ready'].includes(order.status);
      const isOutboundNotDroppedOff = order.status === 'Outbound' && !hasBeenDroppedOff(order);

      if (!needsPrep && !isOutboundNotDroppedOff) return false;
      if (!order.ship_date) return false;

      const shipDate = moment(order.ship_date);
      return shipDate.isValid() && shipDate.isBefore(today, 'day');
    });
    console.log(`Found ${pastShipDateNotDroppedOff.length} orders past ship date not dropped off`);
    urgentOrders.push(...pastShipDateNotDroppedOff);

    // 2. Events starting today but not shipped
    const eventsToday = orders.filter(order => {
      // Include orders in New or Ready status AND Outbound orders that haven't been dropped off
      const needsAction =
        ['New', 'Ready'].includes(order.status) || (order.status === 'Outbound' && !hasBeenDroppedOff(order));

      if (!needsAction || !order.event_date) return false;

      const eventDate = moment(order.event_date);
      return eventDate.isValid() && eventDate.isSame(today, 'day');
    });
    console.log(`Found ${eventsToday.length} events starting today that haven't been shipped`);
    urgentOrders.push(...eventsToday);

    // 3. Late returns
    const lateReturns = orders.filter(order => {
      if (!['Delivered', 'Packing'].includes(order.status) || !order.return_date) return false;

      const returnDate = moment(order.return_date);
      return returnDate.isValid() && returnDate.isBefore(today, 'day');
    });
    console.log(`Found ${lateReturns.length} late returns`);
    urgentOrders.push(...lateReturns);

    // 4. Need check-in
    const needCheckin = orders.filter(order => order.status === 'Returned');
    console.log(`Found ${needCheckin.length} booths that need to be checked in`);
    urgentOrders.push(...needCheckin);

    // Remove duplicates from urgent orders array
    const urgentOrdersUnique = Array.from(new Set(urgentOrders.map(order => order.id))).map(id =>
      urgentOrders.find(order => order.id === id)
    );

    console.log(`Total unique urgent orders: ${urgentOrdersUnique.length}`);

    return {
      statusCounts,
      toShipSoon,
      allShipThisWeek,
      returning,
      needsPacking,
      urgentOrders: urgentOrdersUnique,
      hasBeenDroppedOff,
      getEffectiveShippingStatus,
    };
  };

  // Navigate to order details
  const viewOrder = orderId => {
    props.history.push(`/orders/${orderId}`);
  };

  // Navigate to order list with filter
  const viewOrdersByStatus = status => {
    props.history.push(`/orders?status=${status}`);
  };

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: isBlinking ? 'rgba(76, 175, 80, 0.15)' : 'transparent',
        transition: 'background-color 0.3s ease-in-out', // Add transition for smoother effect
      }}
    >
      <Container maxWidth='lg'>
        <Typography variant='h4' className={classes.title}>
          Operations Dashboard
        </Typography>

        <Subscription subscription={GET_DASHBOARD_ORDERS}>
          {result => {
            const { loading, error, data } = result;
            if (loading) return <Loading fixed />;
            if (error) return <Typography color='error'>Error loading dashboard data: {error.message}</Typography>;

            const dashboardData = prepareData(data.orders);
            if (!dashboardData) return <Typography>No active orders found.</Typography>;

            return (
              <>
                {/* Key Stats Section */}
                <Grid container spacing={3}>
                  {/* New Orders Tile - Always visible */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      className={classes.card}
                      style={{
                        backgroundColor: isBlinking ? 'rgba(76, 175, 80, 0.15)' : 'white',
                        transition: 'background-color 0.3s ease-in-out',
                        borderLeft: '4px solid #4caf50',
                      }}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.titleRow}>
                          <Box
                            className={`${classes.iconContainer}`}
                            style={{ backgroundColor: 'rgba(76, 175, 80, 0.12)' }}
                          >
                            <Icon className={classes.icon}>fiber_new</Icon>
                          </Box>
                          <Typography className={classes.statLabel}>New Orders</Typography>
                        </div>
                        <div className={classes.numberRow}>
                          <Typography className={classes.statNumber}>{newOrdersCount}</Typography>
                        </div>
                        {lastSeenTimestamp && (
                          <Typography
                            variant='caption'
                            color='textSecondary'
                            style={{ display: 'block', marginTop: '8px' }}
                          >
                            Last viewed: {lastSeenTimestamp}
                          </Typography>
                        )}
                        <Button
                          variant='contained'
                          color='primary'
                          fullWidth
                          onClick={markOrdersAsSeen}
                          disabled={newOrdersCount === 0}
                          style={{
                            marginTop: '16px',
                            fontWeight: 'bold',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                            padding: '8px 16px',
                            opacity: newOrdersCount === 0 ? 0.7 : 1,
                          }}
                          startIcon={<Icon>visibility</Icon>}
                        >
                          Mark as Seen
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Card className={classes.card} onClick={() => props.history.push('/outbound-schedule')}>
                      <CardContent className={classes.cardContent}>
                        <div className={classes.titleRow}>
                          <Box className={`${classes.iconContainer} ${classes.messageIcon}`}>
                            <Icon className={classes.icon}>local_shipping</Icon>
                          </Box>
                          <Typography className={classes.statLabel}>To Ship This Week</Typography>
                        </div>
                        <div className={classes.numberRow}>
                          <Typography className={classes.statNumber}>{dashboardData.allShipThisWeek.length}</Typography>
                          <Icon className={classes.chevronIcon}>chevron_right</Icon>
                        </div>

                        {/* Shipping status summary */}
                        {dashboardData.allShipThisWeek.length > 0 && (
                          <Box mt={1}>
                            <Grid container spacing={1}>
                              {(() => {
                                // Count orders by effective shipping status
                                const needsPrep = dashboardData.allShipThisWeek.filter(o => o.status === 'New').length;

                                // Ready includes both Ready status and Outbound status without tracking movement
                                const readyToShip = dashboardData.allShipThisWeek.filter(
                                  o =>
                                    o.status === 'Ready' ||
                                    (o.status === 'Outbound' && !dashboardData.hasBeenDroppedOff(o))
                                ).length;

                                // Only count as shipped if it's delivered or has actual shipping movement
                                const alreadyShipped = dashboardData.allShipThisWeek.filter(
                                  o =>
                                    o.status === 'Delivered' ||
                                    (o.status === 'Outbound' && dashboardData.hasBeenDroppedOff(o))
                                ).length;

                                return (
                                  <>
                                    {needsPrep > 0 && (
                                      <Grid item>
                                        <Typography
                                          variant='body2'
                                          style={{
                                            color: '#f57c00',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(245, 124, 0, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          {needsPrep} Need Prep
                                        </Typography>
                                      </Grid>
                                    )}

                                    {readyToShip > 0 && (
                                      <Grid item>
                                        <Typography
                                          variant='body2'
                                          style={{
                                            color: '#1976d2',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(25, 118, 210, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          {readyToShip} Ready For Drop-Off
                                        </Typography>
                                      </Grid>
                                    )}

                                    {alreadyShipped > 0 && (
                                      <Grid item>
                                        <Typography
                                          variant='body2'
                                          style={{
                                            color: '#4caf50',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(76, 175, 80, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          {alreadyShipped} In Transit
                                        </Typography>
                                      </Grid>
                                    )}
                                  </>
                                );
                              })()}
                            </Grid>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card className={classes.card} onClick={() => viewOrdersByStatus('Inbound')}>
                      <CardContent className={classes.cardContent}>
                        <div className={classes.titleRow}>
                          <Box className={`${classes.iconContainer} ${classes.maintenanceIcon}`}>
                            <Icon className={classes.icon}>assignment_return</Icon>
                          </Box>
                          <Typography className={classes.statLabel}>Returning to Warehouse</Typography>
                        </div>
                        <div className={classes.numberRow}>
                          <Typography className={classes.statNumber}>{dashboardData.returning.length}</Typography>
                          <Icon className={classes.chevronIcon}>chevron_right</Icon>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      className={classes.card}
                      style={
                        dashboardData.urgentOrders.some(
                          order =>
                            ['New', 'Ready'].includes(order.status) &&
                            moment(order.event_date).isSame(moment().startOf('day'), 'day')
                        )
                          ? { backgroundColor: 'rgba(229, 57, 53, 0.12)' }
                          : {}
                      }
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.titleRow}>
                          <Box className={`${classes.iconContainer} ${classes.urgentIcon}`}>
                            <Icon className={classes.icon}>priority_high</Icon>
                          </Box>
                          <Typography className={classes.statLabel}>Need Immediate Attention</Typography>
                        </div>
                        <div className={classes.numberRow}>
                          <Typography className={classes.statNumber}>{dashboardData.urgentOrders.length}</Typography>
                          <Icon className={classes.chevronIcon}>chevron_right</Icon>
                        </div>
                        {dashboardData.urgentOrders.some(
                          order =>
                            ['New', 'Ready'].includes(order.status) &&
                            moment(order.event_date).isSame(moment().startOf('day'), 'day')
                        ) && (
                          <Typography
                            variant='body2'
                            style={{
                              color: '#e53935',
                              fontWeight: 'bold',
                              marginTop: '8px',
                            }}
                          >
                            CRITICAL: Event scheduled today!
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {/* Urgent Actions Section */}
                {dashboardData.urgentOrders.length > 0 && (
                  <>
                    <Typography variant='h5' className={classes.sectionTitle}>
                      Urgent Actions
                    </Typography>
                    <Paper className={classes.paper}>
                      <List className={classes.actionList}>
                        {dashboardData.urgentOrders.map(order => {
                          const today = moment().startOf('day');

                          // Check different urgent conditions
                          const isShippingToday =
                            ['New', 'Ready'].includes(order.status) && moment(order.ship_date).isSame(today, 'day');

                          const isEventToday =
                            ['New', 'Ready'].includes(order.status) && moment(order.event_date).isSame(today, 'day');

                          const isLateReturn =
                            ['Delivered', 'Packing'].includes(order.status) &&
                            order.return_date &&
                            moment(order.return_date).isBefore(today);

                          const needsCheckin = order.status === 'Returned';

                          // New condition: Outbound but not dropped off
                          const needsDropOff = order.status === 'Outbound' && !dashboardData.hasBeenDroppedOff(order);

                          // Past ship date but not dropped off
                          const isPastShipDate = order.ship_date && moment(order.ship_date).isBefore(today);
                          const isPastShipDateNotDroppedOff = needsDropOff && isPastShipDate;

                          let actionText = '';
                          let iconName = '';
                          let urgencyLevel = '';

                          if (isEventToday) {
                            actionText = `URGENT: Booth #${order.booth_id} scheduled for event TODAY but not shipped!`;
                            iconName = 'error';
                            urgencyLevel = 'critical';
                          } else if (isPastShipDateNotDroppedOff) {
                            actionText = `LATE: Booth #${
                              order.booth_id
                            } needs to be dropped off at UPS! (Ship date was ${moment(order.ship_date).format(
                              'MM/DD/YYYY'
                            )})`;
                            iconName = 'shopping_cart';
                            urgencyLevel = 'high';
                          } else if (isShippingToday) {
                            if (needsDropOff) {
                              actionText = `Drop off booth #${order.booth_id} at UPS today for ${order.event_name}!`;
                              iconName = 'shopping_cart';
                              urgencyLevel = 'high';
                            } else {
                              actionText = `Ship booth #${order.booth_id} to ${order.shipping_city}, ${order.shipping_state} today!`;
                              iconName = 'local_shipping';
                              urgencyLevel = 'high';
                            }
                          } else if (isLateReturn) {
                            actionText = `Follow up on late return for booth #${order.booth_id} (${moment(
                              order.return_date
                            ).fromNow()})`;
                            iconName = 'phone';
                            urgencyLevel = 'medium';
                          } else if (needsCheckin) {
                            actionText = `Check in returned booth #${order.booth_id} from ${order.event_name}`;
                            iconName = 'assignment_turned_in';
                            urgencyLevel = 'normal';
                          }

                          // Extra styling for critical urgency
                          const listItemStyle =
                            urgencyLevel === 'critical' ? { backgroundColor: 'rgba(229, 57, 53, 0.08)' } : {};

                          return (
                            <ListItem
                              key={order.id}
                              button
                              onClick={() => viewOrder(order.id)}
                              className={classes.listItem}
                              style={listItemStyle}
                            >
                              <div className={`${classes.itemBorder} ${classes.urgentBorder}`} />
                              <ListItemIcon>
                                <Icon>{iconName}</Icon>
                              </ListItemIcon>
                              <ListItemText
                                primary={actionText}
                                secondary={`${order.event_name} | Order #${order.id}`}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Paper>
                  </>
                )}
                <Grid container spacing={3} style={{ marginTop: '24px' }}>
                  {/* Shipping This Week Section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant='h5' className={classes.sectionTitle}>
                      Shipping This Week
                    </Typography>
                    <Paper className={classes.paper}>
                      {dashboardData.allShipThisWeek.length > 0 ? (
                        <List className={classes.actionList}>
                          {dashboardData.allShipThisWeek.map(order => {
                            // Determine border color based on status
                            let borderClass = classes.warningBorder;
                            let iconName = 'local_shipping';

                            // Check effective shipping status
                            const effectiveStatus = dashboardData.getEffectiveShippingStatus(order);

                            // Already shipped (Delivered or Outbound with tracking movement)
                            const isShipped =
                              order.status === 'Delivered' ||
                              (order.status === 'Outbound' && dashboardData.hasBeenDroppedOff(order));

                            // Needs to be dropped off (Outbound without tracking movement)
                            const needsDropOff = order.status === 'Outbound' && !dashboardData.hasBeenDroppedOff(order);

                            if (isShipped) {
                              borderClass = classes.successBorder;
                              iconName = 'check_circle';
                            } else if (needsDropOff) {
                              iconName = 'shopping_cart';
                            }

                            return (
                              <ListItem
                                key={order.id}
                                button
                                onClick={() => viewOrder(order.id)}
                                className={classes.listItem}
                                style={isShipped ? { opacity: 0.8 } : {}}
                              >
                                <div className={`${classes.itemBorder} ${borderClass}`} />
                                <ListItemIcon>
                                  <Icon>{iconName}</Icon>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Box display='flex' alignItems='center'>
                                      <Typography component='span'>
                                        {`${order.event_name} - ${moment(order.ship_date).format('MM/DD/YYYY')}`}
                                      </Typography>

                                      {/* Show effective status badge */}
                                      {isShipped && (
                                        <Typography
                                          component='span'
                                          style={{
                                            marginLeft: '8px',
                                            color: '#4caf50',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(76, 175, 80, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '4px',
                                          }}
                                        >
                                          {effectiveStatus}
                                        </Typography>
                                      )}

                                      {needsDropOff && (
                                        <Typography
                                          component='span'
                                          style={{
                                            marginLeft: '8px',
                                            color: '#fb8c00',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(251, 140, 0, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '4px',
                                          }}
                                        >
                                          Needs Drop-Off
                                        </Typography>
                                      )}

                                      {order.status === 'New' && (
                                        <Typography
                                          component='span'
                                          style={{
                                            marginLeft: '8px',
                                            color: '#f57c00',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(245, 124, 0, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '4px',
                                          }}
                                        >
                                          Needs Prep
                                        </Typography>
                                      )}

                                      {order.status === 'Ready' && (
                                        <Typography
                                          component='span'
                                          style={{
                                            marginLeft: '8px',
                                            color: '#1976d2',
                                            fontWeight: 500,
                                            fontSize: '0.85rem',
                                            backgroundColor: 'rgba(25, 118, 210, 0.1)',
                                            padding: '2px 8px',
                                            borderRadius: '4px',
                                          }}
                                        >
                                          Ready
                                        </Typography>
                                      )}
                                    </Box>
                                  }
                                  secondary={`Booth #${order.booth_id} | ${order.shipping_city}, ${order.shipping_state}`}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : (
                        <CardContent>
                          <Typography variant='body1'>No shipments scheduled for this week.</Typography>
                        </CardContent>
                      )}
                    </Paper>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.actionButton}
                      onClick={() => props.history.push('/outbound-schedule')}
                    >
                      View Outbound Schedule
                    </Button>
                  </Grid>

                  {/* Booths Returning Section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant='h5' className={classes.sectionTitle}>
                      Booths Returning
                    </Typography>
                    <Paper className={classes.paper}>
                      {dashboardData.returning.length > 0 ? (
                        <List className={classes.actionList}>
                          {dashboardData.returning.map(order => (
                            <ListItem
                              key={order.id}
                              button
                              onClick={() => viewOrder(order.id)}
                              className={classes.listItem}
                            >
                              <div className={`${classes.itemBorder} ${classes.successBorder}`} />
                              <ListItemIcon>
                                <Icon>assignment_return</Icon>
                              </ListItemIcon>
                              <ListItemText
                                primary={`${order.event_name} - ETA: ${
                                  order.return_date ? moment(order.return_date).format('MM/DD/YYYY') : 'Unknown'
                                }`}
                                secondary={`Booth #${order.booth_id} | Tracking: ${
                                  order.inbound_tracking || 'No tracking yet'
                                }`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <CardContent>
                          <Typography variant='body1'>No booths currently returning.</Typography>
                        </CardContent>
                      )}
                    </Paper>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.actionButton}
                      onClick={() => props.history.push('/inbound-schedule')}
                    >
                      View Inbound Schedule
                    </Button>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {/* Status Overview Section */}
                <Typography variant='h5' className={classes.sectionTitle}>
                  Order Status Overview
                </Typography>
                <Paper className={classes.paper}>
                  <CardContent>
                    <Grid container spacing={3}>
                      {Object.entries(dashboardData.statusCounts).map(([status, count]) => (
                        <Grid item xs={6} sm={4} md={3} key={status}>
                          <Card
                            className={classes.card}
                            onClick={() => viewOrdersByStatus(status)}
                            style={{ cursor: 'pointer' }}
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography variant='h5' component='h2'>
                                {count}
                              </Typography>
                              <Typography color='textSecondary'>{status}</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Paper>
              </>
            );
          }}
        </Subscription>
      </Container>
    </div>
  );
}

const GET_DASHBOARD_ORDERS = gql`
  subscription getDashboardOrders {
    orders(where: { active: { _eq: true }, status: { _nin: ["Done", "Canceled"] } }) {
      ...Order
    }
  }
  ${fragments.order}
  ${fragments.shipmentActivity}
`;

export default Dashboard;
